<template>
  <div class="events-monitor d-flex-r d-flex-a-c h-spacing-4">
    <template v-if="!fetching">
      <EventsUl
        :events="events"
        :current-event="currentEvent"
        @click="currentEvent = $event"
      >
        <template v-slot:current-event>
          <slot name="current-event" />
        </template>
      </EventsUl>

      <Register
        v-if="register"
        :register="register"
        :boxes="events[currentEvent].boxes"
      />
    </template>

    <Progress v-else variant="odd" with-overlay="background" />
  </div>
</template>

<script>
import { EventsTableTransformer, Traveler } from '@odd-industries/odd-charting';
import {
  DATASETS,
  METRICS_COLLECTION_REF,
  REGISTERS_COLLECTION_REF,
} from './constants';
import { functions } from '@/helpers';

// components
import EventsUl from '@/components/EventsUl.vue';
import Register from '@/components/Register.vue';

export default {
  name: 'EventsMonitor',
  components: { EventsUl, Register },
  props: {
    value: { type: Number, default: undefined },
  },
  data() {
    return {
      events: [],
      currentEvent_: null,

      traveler: Traveler.fromRemoteDatasets(METRICS_COLLECTION_REF, DATASETS),

      interval: null,

      fetching: false,
    };
  },
  computed: {
    currentEvent: {
      get() {
        return this.currentEvent_;
      },
      set(newValue) {
        this.currentEvent_ = newValue % this.events.length;

        this.$emit('input', this.currentEvent);
      },
    },

    register() {
      if (!_.isFinite(this.currentEvent)) return;

      return this.events[this.currentEvent].registers[0];
    },
  },
  watch: {
    value(current, previous) {
      if (current === previous) return;

      this.currentEvent = current;
    },
  },
  async created() {
    await this.traveler.setup();

    this.setupInterval();
  },
  beforeDestroy() {
    this.teardownInterval();
  },
  methods: {
    async fetchRegisterSignedURI(register) {
      return functions.fetchFileSignedURI(register);
    },
    async fetchEventRegister(event) {
      return {
        ...event,

        registers: await Promise.all(
          _.map(event.registersIds, async registerId => {
            const register = (
              await REGISTERS_COLLECTION_REF.doc(registerId).get()
            ).data();

            register.uri = this.fetchRegisterSignedURI(register);

            return register;
          })
        ),
      };
    },

    async fetchEvents() {
      const REMOTE_GRANULARITY = 'DAILY';

      const { asRemoteDate } = this.$time.format;

      const now = moment();
      const yesterday = moment(now)
        .subtract(1, 'day')
        .startOf('day');
      const remoteDateRange = [asRemoteDate(yesterday), asRemoteDate(now)];

      await this.traveler.fetch(remoteDateRange, REMOTE_GRANULARITY);

      const datasets = this.traveler.asChartJsDatasets();
      const transformer = new EventsTableTransformer();
      const transformedDatasets = await transformer.transform(
        datasets,
        REGISTERS_COLLECTION_REF
      );

      this.events = _.orderBy(
        _.flatten(
          await Promise.all(
            _.map(transformedDatasets.datasets, dataset =>
              Promise.all(
                _.map(dataset.data.slice(0, 10), this.fetchEventRegister)
              )
            )
          )
        ),
        'timestamp',
        'desc'
      );
      this.currentEvent = this.events.length > 0 ? 0 : null;
    },

    async fetch(quiet = false) {
      try {
        if (!quiet) this.fetching = true;

        await this.fetchEvents();
      } finally {
        if (!quiet) this.fetching = false;
      }
    },

    setupInterval() {
      const { intervalDuration } = this.$options;

      const handler = (quiet = true) => {
        this.fetch(quiet);

        return handler;
      };

      this.teardownInterval();
      this.interval = setInterval(handler(false), intervalDuration);
    },
    teardownInterval() {
      clearInterval(this.interval);
    },
  },

  intervalDuration: 900000, // 15 minutes
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
$background-blur: 10px
$background-z-index: 0

.events-monitor
  padding: 2.5% 5%

  +p-relative
  +size(100%)

  & > .events-ul
    z-index: 1

    flex: 1.5

  & > .register
    flex: 3

.events-monitor__background
  justify-content: center

  +p-absolute($background-z-index, 0)
  +size(auto)

  & > .register__body
    filter: blur($background-blur)

    & > img
      width: auto
      height: 100%
</style>
