<template>
  <div class="v-monitor">
    <AutomaticEventsMonitor />
  </div>
</template>

<script>
// components
import AutomaticEventsMonitor from '@/components/AutomaticEventsMonitor.vue';

export default {
  name: 'VMonitor',
  components: { AutomaticEventsMonitor },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

.v-monitor
  height: 100%

  +d-flex-r(center, center)
</style>
