import { db } from '@/config/firebase';

export const DATASETS = [
  {
    metrics: [
      {
        id: 'worker-no-comply-ppe',
        field: 'events',
      },
    ],
    label: 'Trabajadores no cumplen EPP',
  },
  {
    metrics: [
      {
        id: 'truck-entry',
        field: 'events',
      },
    ],
    label: 'truck-entry',
  },
  {
    metrics: [
      {
        id: 'truck-exit',
        field: 'events',
      },
    ],
    label: 'truck-exit',
  },
  {
    metrics: [
      {
        id: 'tape-bottom-assembly',
        field: 'events',
      },
    ],
    label: 'tape-bottom-assembly',
  },
  {
    metrics: [
      {
        id: 'tape-top-assembly',
        field: 'events',
      },
    ],
    label: 'tape-top-assembly',
  },
  {
    metrics: [
      {
        id: 'tape-bottom-disassembly',
        field: 'events',
      },
    ],
    label: 'tape-bottom-disassembly',
  },
  {
    metrics: [
      {
        id: 'tape-top-disassembly',
        field: 'events',
      },
    ],
    label: 'tape-top-disassembly',
  },
  {
    metrics: [
      {
        id: 'no-rope-truck-entering',
        field: 'events',
      },
    ],
    label: 'no-rope-truck-entering',
  },
  {
    metrics: [
      {
        id: 'no-rope-truck-leaving',
        field: 'events',
      },
    ],
    label: 'no-rope-truck-leaving',
  },
  {
    metrics: [
      {
        id: 'no-workers-rope-assembled',
        field: 'events',
      },
    ],
    label: 'no-workers-rope-assembled',
  },
];

export const METRICS_COLLECTION_REF = db.collection('Metrics');
export const REGISTERS_COLLECTION_REF = db.collection('Registers');
