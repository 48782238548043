<template>
  <EventsMonitor v-model="currentEvent" class="automatic-events-monitor">
    <template v-slot:current-event>
      <div
        :style="{
          '--duration': `${$options.progressTick}ms`,
          '--progress': `${intervalProgress / $options.intervalDuration}`,
        }"
        class="automatic-events-monitor__progress"
      >
        <div />
      </div>
    </template>
  </EventsMonitor>
</template>

<script>
// components
import EventsMonitor from '@/components/EventsMonitor.vue';

export default {
  name: 'AutomaticEventsMonitor',
  components: { EventsMonitor },
  data() {
    return {
      currentEvent: null,

      interval: null,
      intervalProgress: null,
    };
  },
  created() {
    this.setupInterval();
  },
  beforeDestroy() {
    this.teardownInterval();
  },
  methods: {
    moveToNext() {
      if (!_.isFinite(this.currentEvent)) return;

      this.currentEvent += 1;
    },

    setupInterval() {
      const { intervalDuration, progressTick } = this.$options;

      const handler = (() => {
        this.intervalProgress = 0;

        return () => {
          this.intervalProgress += progressTick;

          if (this.intervalProgress % intervalDuration === 0) {
            this.intervalProgress = 0;

            this.moveToNext();
          }
        };
      })();

      this.teardownInterval();
      this.interval = setInterval(handler, progressTick);
    },
    teardownInterval() {
      clearInterval(this.interval);
    },
  },

  intervalDuration: 10000, // 10 seconds
  progressTick: 100,
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
$progress-height: 4px

.automatic-events-monitor__progress
  width: 100%
  height: $progress-height

  & > div
    background-color: var(--c-green)

    transform-origin: left
    transform: scaleX(var(--progress))

    transition: transform var(--duration) linear

    +size(100%)
</style>
