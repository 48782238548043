<template>
  <div class="register d-flex-r d-flex-a-c">
    <div class="register__body b-radius-1 o-hidden elevation-10">
      <img v-if="uri" :src="uri" @load="setBoxes" />

      <template v-if="mergedBoxes">
        <div
          v-for="(box, index) in mergedBoxes"
          :key="`box-${index}`"
          class="register__box"
          :style="getBoxAsStyle(box)"
        />
      </template>
    </div>
  </div>
</template>

<script>
const BOXES_PATH = 'process_result.WORKERS_DETECTOR.boxes';

export default {
  name: 'Register',
  props: {
    register: { type: Object, required: true },
    boxes: { type: Array, default: undefined },
  },
  data() {
    return {
      uri: null,

      mergedBoxes: null,
    };
  },
  watch: {
    register: {
      async handler() {
        this.uri = await this.register.uri;
      },
      immediate: true,
    },
  },
  methods: {
    setBoxes() {
      this.mergedBoxes = this.boxes || _.get(this.register, BOXES_PATH);
    },

    getBoxAsStyle(box) {
      return {
        '--top': `${box.top * 100}%`,
        '--right': `${100 - box.right * 100}%`,
        '--bottom': `${100 - box.bottom * 100}%`,
        '--left': `${box.left * 100}%`,
      };
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
$box-border-width: 2px
$box-z-index: 1

.register
  height: 100%

.register__body
  +p-relative

  & > img
    display: block

    width: 100%

.register__box
  border-style: solid
  border-width: $box-border-width

  +p-absolute($box-z-index, var(--top), var(--right),  var(--bottom), var(--left))

// color
.register__box
  border-color: var(--c-red)
</style>
