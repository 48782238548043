<template>
  <div class="events-ul">
    <div class="events-ul__body d-flex-c pv-3 ph-5 v-spacing-3">
      <div
        v-for="(event, index) in events"
        :key="`event-${index}`"
        ref="events"
        :style="{ '--scale': getEventScale(index) }"
        :class="[
          'events-ul__event',
          { 'events-ul__event--active': index === currentEvent },
          'card',
        ]"
        @click="$emit('click', index)"
      >
        <div class="d-flex-c pv-2 ph-3">
          <t sm medium no-wrap>
            {{ $time.moment(event.timestamp).format('DD/MM - HH:mm A') }}
          </t>

          <t bold class="mt-1">{{
            $t(`c.events-table.types.${event.type}`)
          }}</t>
        </div>

        <slot v-if="index === currentEvent" name="current-event" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EventsUl',
  props: {
    events: { type: Array, required: true },
    currentEvent: { type: Number, default: undefined },
  },
  watch: {
    currentEvent() {
      const { currentEvent } = this;
      if (!_.isFinite(currentEvent)) return;
      const { events } = this.$refs;
      if (!_.isArray(events)) return;

      const el = events[currentEvent];

      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    },
  },
  methods: {
    getEventScale(index) {
      const difference = Math.abs(index - this.currentEvent);

      let increment = 0;

      if (difference === 0) increment = 0.15;
      else if (difference === 1) increment = 0.075;

      return increment + 1;
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
$event-height: 42px

.events-ul
  height: 100%

  overflow-y: auto

  +no-scrollbar

.events-ul__event
  opacity: .5

  transform: scale(var(--scale))
  transition: all $duration-slowly

  +button

  .h-splitter
    height: 100%

.events-ul__event:hover
  opacity: 1

// color
.events-ul__event
  & > .e-text
    --color: var(--c-secondary)

  .h-splitter
    background-color: var(--c-gray-1)

.events-ul__event--active
  opacity: 1
</style>
